

.App {
  text-align: center;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Italic.woff2') format('woff2'),
  url('assets/fonts/Montserrat-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Bold.woff2') format('woff2'),
  url('assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.text-lg {
  font-size: 1.25rem; /* Adjust the font size as needed */
}

input {
  @apply px-3 outline-none lg:px-6 py-1 lg:py-3 text-sm lg:text-lg;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  @apply px-[3%] sm:px-[5%] md:px-[10%] lg:px-[14%] xl:px-[16%] 2xl:px-[20%] py-8 md:py-16;
}

.conditions {
  @apply text-left;
}
.conditions h1 {
  @apply text-primary text-lg md:text-xl font-bold my-6;
}
.conditions ul {
  @apply list-disc list-inside marker:text-primary;
}
.conditions ul li,
.conditions p {
  @apply text-sm md:text-base;
}
.conditions table {
  @apply w-full lg:w-[35%];
}
.conditions th {
  @apply opacity-30 text-xs md:text-sm;
}
.conditions td {
  @apply text-xs md:text-sm;
}

.link-cards-column {
  @apply flex flex-col h-[500px] sm:h-[650px] lg:aspect-[.4] lg:h-auto gap-4;
}
.link-card {
  @apply rounded-xl overflow-hidden relative flex-grow transition-all flex-[1] hover:flex-[3] duration-500;
}

.link-card-image {
  @apply hover:scale-150 duration-300 transition transform object-cover;
}

.link-card-text-container {
  @apply flex justify-between absolute bottom-0 p-2 items-center w-full;
}
.link-card-text {
  @apply text-xs 2xl:text-xl font-extrabold text-left;
}

.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border: 2px solid white !important;
  margin: 0 8px !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  width: 19% !important;
  height: 110% !important;
  background-color: rgba(
    0,
    0,
    0,
    0
  ) !important; /* semi-transparent background */
  display: flex !important;
  align-items: center !important;
  opacity: 0 !important;
  transition: background-color 0.3s !important;
}

.swiper-button-next {
  justify-content: flex-start !important;
  padding-left: 30px !important;
}

.swiper-button-prev {
  padding-right: 30px !important;
  justify-content: flex-end !important;
}

.swiper-wrapper{
  width: 100vw !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
  opacity: 1 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 5rem !important;
  font-weight: 800 !important;
}
.zoom-80{
  zoom: 0.8;
}

@media (max-width: 767px) {
  .zoom-80 {
    zoom: 0.6; /* Zoom for mobile devices */
  }
}

.swiper-button-next {
  right: 0px !important; /* adjust to position correctly */
}

.swiper-button-prev {
  left: 0px !important; /* adjust to position correctly */
}

/* Centering the navigation buttons vertically */
.swiper-button-next,
.swiper-button-prev {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

/* Customize the Swiper scrollbar */
.swiper-scrollbar {
  background: #675a5a !important; /* Light grey background for the scrollbar track */
  border-radius: 10px !important; /* Rounded corners for the scrollbar track */
  height: 15px !important; /* Height of the scrollbar track */
  width: 40% !important; /* Width of the scrollbar track */
  position: relative !important; /* Position the scrollbar track relative to the swiper container */
  margin: 0 auto !important;
}

.swiper-scrollbar-drag {
  background: #0090df !important; /* Gradient for the scrollbar thumb */
  border-radius: 10px !important; /* Rounded corners for the scrollbar thumb */
}

@media screen and (max-width: 600px) {
  .swiper-scrollbar {
    width: 80% !important;
  }
}

@media screen and (max-width: 1536px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 14% !important;
  }
}

.swiper {
  padding: 0 0 10px 0 !important;
}

/* WebKit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: #0090df !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #32b7ff !important;
}

/* Firefox */
html {
  scrollbar-width: thin !important;
  scrollbar-color: #0090df #f1f1f1 !important;
  scroll-behavior: smooth !important;
}

.bg-pattern {
  /* width: 100vw;
  height: 100vh; */
  background-image: url("assets/img/backgrounds/pattern.svg");
  background-repeat: repeat;
}

/* Custom Checkbox Styles */
.custom-checkbox:checked {
  border: 2px solid black !important;
  background-color: white !important;
}

.footer-links a {
  @apply text-[.6rem] 2xl:text-xs;
}
